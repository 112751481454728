<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Project Code"
              v-model="filter.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="options.statuses"
              size="lg"
            ></b-form-select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="text-right">
              <button class="btn btn-primary btn-md" @click="onSearch">
                <i class="flaticon2-search-1"></i> Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <complete-table :data="this.projectList" :loading="false">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Project Code</span>
              </th>
              <th style="min-width: 100px">Project Code</th>
              <th style="min-width: 100px">Status</th>
              <th style="min-width: 100px">Owner</th>
              <th style="min-width: 101px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pl-0">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.name }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.status }}
                </span>
              </td>

              <td>
                <span class="text-muted font-weight-bold d-block">
                  {{ item.owner }}
                </span>
              </td>

              <td class="pr-0">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="toEditPage(item)"
                >
                  <i class="flaticon-edit"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FILTER_PROJECT_DETAIL } from "@/core/services/store/project.module";
import { mapGetters } from "vuex";
import {
  SHOW_PROJECT_DESIGN,
  SHOW_PROJECT_ADMIN,
} from "../../../core/services/store/dl.design.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";

export default {
  name: "check-list",
  components: { CompleteTable },

  data() {
    return {
      filter: {
        status: null,
        code: null,
      },
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
      },
      prevChecklist: "prev",
      nextChecklist: "Next",
      paginationChecklist: true,
      loaderNextChecklist: true,
    };
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },
    toEditPage(data) {
      this.$router.push({
        name: "distribution-check",
        params: {
          id: data.code,
        },
      });
    },

    onSearch() {
      const filter = {
        params: {
          code: this.filter.code == null ? "" : this.filter.code,
          status: this.filter.status === null ? "" : this.filter.status,
        },
      };

      this.$store.dispatch(SHOW_PROJECT_DESIGN, filter);
      this.$store.dispatch(SHOW_PROJECT_ADMIN, filter);
    },

    onFilter(param, jobdesk) {
      const filter = {
        jobdesk: jobdesk,
        params: {
          code: param.code == null ? "" : param.code,
          status: param.status === null ? "" : param.status,
        },
      };
      this.$store
        .dispatch(FILTER_PROJECT_DETAIL, filter)
        .then(() => console.log("project all", this.currentProjectDetails));
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Checklist Distribution" }]);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(SHOW_PROJECT_ADMIN);
  },
  computed: {
    ...mapGetters(["currentProjectDetails"]),

    projectList() {
      let projectDesign = this.$store.getters.getterProjectDesign;
      let projectAdmin = this.$store.getters.getterProjectAdmin;

      return this.merge(projectAdmin, projectDesign, "code");
    },

    filteredProject() {
      const code = this.filter.code;
      const status = this.filter.status;

      return this.projectList?.filter(
        (proyek) =>
          (code
            ? proyek.code?.toUpperCase()?.includes(code?.toUpperCase())
            : true) && (status ? proyek.status === status : true)
      );
    },
  },
};
</script>

<style></style>
